import React, { useEffect } from 'react';
import enUS from '../tokens/en-US.json';
import esES from '../tokens/es-ES.json';
import jpJP from '../tokens/jp-JP.json';
import ptBR from '../tokens/pt-BR.json';
import arrowIcon from '../assets/icons/arrow_teal.svg';

export const translations = {
  'en-US': enUS,
  'es-ES': esES,
  'jp-JP': jpJP,
  'pt-BR': ptBR,
};

export const Text = ({ languageCode, token }) => <span dangerouslySetInnerHTML={{ __html: translations[languageCode][token] }}></span>;

export const Button = ({ languageCode, onClick, token, promotoken, tooltiptoken, icon, new_icon, gray_icon, url, id, className }) => {

  const isStgFlag = process.env.REACT_APP_IS_STG === 'true';

  useEffect(() => {
    if (isStgFlag) {
      console.log('Staging environment');
    } else {
      console.log('Production environment');
    }
  }, []);

  const translatedUrl = isStgFlag
    ? `${translations[languageCode][`${url}-stg`]}`
    : `${translations[languageCode][url]}`

  return(
    <div className='cta-button'>
    {/* <div id={id + '-left-icon'} className='left-block'><span className='left-copy'><span dangerouslySetInnerHTML={{__html: translations[languageCode][new_icon]}}></span></span></div> */}
    <a id={id} className={className} onClick={onClick} href={translatedUrl ? translatedUrl : ''} >
      <img src={icon} alt="" className="left-mobile-block" /><div className="left-mobile-block"><span dangerouslySetInnerHTML={{ __html: translations[languageCode][new_icon] }}></span></div>
      <span id={id + '-copy'} className={className + '-copy'} dangerouslySetInnerHTML={{ __html: translations[languageCode][token] }}></span>
      <img src={arrowIcon} className="right-mobile-block" alt="" />
    </a>
    <div className='tool-tip' id="tool-tip">
      <span className='tool-tip-logo'>?</span>
      <div className={id + '-tool-tip-content tool-tip-content'} id='tool-tip-content'>
        <span dangerouslySetInnerHTML={{ __html: translations[languageCode][tooltiptoken] }}></span>
      </div>
    </div>

    <div id={id + '-right-icon'} className='right-block promotion'><span className='right-copy' dangerouslySetInnerHTML={{ __html: translations[languageCode][promotoken] }}></span></div>
  </div>
  )
}