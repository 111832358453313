import React from 'react';
import { Text } from './Text';
import enUS from '../tokens/en-US.json';
import esES from '../tokens/es-ES.json';
import jpJP from '../tokens/jp-JP.json';
import ptBR from '../tokens/pt-BR.json';
import StarAllianceLogo from '../assets/Logos/starAlliance.png';

export const translations = {
  'en-US': enUS,
  'es-ES': esES,
  'jp-JP': jpJP,
  'pt-BR': ptBR,
};

export const Footer = ({ languageCode, toggleHiddenBuy, toggleHiddenMultiply, toggleHiddenSubscribe, toggleHiddenGift, toggleHiddenRequestTransfer }) => {
  
  const isStgFlag = process.env.REACT_APP_IS_STG === 'true';

  let buyLink = isStgFlag ? "buy.yourself.link.url-stg" : "buy.yourself.link.url";
  let giftLink = isStgFlag ? "buy.another.link.url-stg" : "buy.another.link.url";
  let multiplyLink = isStgFlag ? "buy.multiply.link.url-stg" : "buy.multiply.link.url";
  let subscribeLink = isStgFlag ? "buy.subscribe.link.url-stg" : "buy.subscribe.link.url";
  let transferLink = isStgFlag ? "transfer.link.url-stg" : "transfer.link.url";
  let requestTransferLink = isStgFlag ? "transfer.another.link.url-stg" : "transfer.another.link.url";

  return(
  <div className='footer-block'>
    <div className="home-content_text">
            <p><Text languageCode={languageCode} token="homeDefault.text" /></p>
            <a href={translations[languageCode]["homeDefault.link.url"]} target="_blank" ><Text languageCode={languageCode} token="homeDefault.text.link" /></a>
    </div>
  <div className="footer-container">
    
    <div className="footer-item">
      <h4><Text languageCode={languageCode} token="footer.united.header" /></h4>
      <a href={translations[languageCode]["united.home.link.url"]} className="footer-united-link-pcta" target="_blank"><Text languageCode={languageCode} token="footer.united.website" /></a>
      <a href={translations[languageCode]["united.privacy.policy.link.url"]} className="footer-privacy-link-pcta" target="_blank"><Text languageCode={languageCode} token="footer.united.privacy" /></a>
      <a href={translations[languageCode]["mileageplus.program.rules.link.url"]} className="footer-rules-link-pcta" target="_blank"><Text languageCode={languageCode} token="footer.united.rules" /></a>
    </div>
    <div className="footer-item">
      <h4><Text languageCode={languageCode} token="footer.buy.header" /></h4>
      <a className="footer-buy-link-pcta"  href={translations[languageCode][buyLink]} target="_blank"><Text languageCode={languageCode} token="footer.buy.buy" /></a>
      <a className="footer-gift-link-pcta"  href={translations[languageCode][giftLink]} target="_blank"><Text languageCode={languageCode} token="footer.buy.gift" /></a>
      <a className="footer-multiply-pcta"  href={translations[languageCode][multiplyLink]} target="_blank"><Text languageCode={languageCode} token="footer.multiply" /></a>
      <a className="footer-subscribe-pcta"  href={translations[languageCode][subscribeLink]} target="_blank"><Text languageCode={languageCode} token="footer.subscribe" /></a>
    </div>
    <div className="footer-item">
      <h4><Text languageCode={languageCode} token="footer.transfer.header" /></h4>
      <a className="footer-transfer-link-pcta" href={translations[languageCode][transferLink]} target="_blank"><Text languageCode={[languageCode]} token="footer.transfer.transfer" /></a>
      <a className="footer-request-transfer-link-pcta" target="_blank" href={translations[languageCode][requestTransferLink]}><Text languageCode={languageCode} token="footer.transfer.request" /></a>
    </div>
    <div className='footer-item'>
    <img src={StarAllianceLogo} alt="" className='starAlliance-logo'/>
    </div>
  </div>
  </div>
  )
};